.listItem{
    color: black;
}

.furhaven.All.active { 
    background-color: #edffe9;
    color: black;
}

.furhaven.All:hover {
    background-color: #5b7d52;
    color: white
}

.furhaven.pennsylvania.active { 
    background-color: #fff8eb;
    color: black;
}

.furhaven.pennsylvania:hover {
    background-color: #908163;
}

.furhaven.kentucky.active {
    background-color: #bff0ff;
    color: black;
}

.furhaven.kentucky:hover {
    background-color: #0087b3;
}

#scrollmenu::-webkit-scrollbar { width: 0 !important }
#scrollmenu { 
    overflow: -moz-scrollbars-none; 
    -ms-overflow-style: none;
}
