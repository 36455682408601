/* Fading Background Stylesheet */

.shipment_late {
    background-color: transparent;
    animation: fadeBackgroundRed 4s infinite;
  }
  
  .shipment_close {
    background-color: transparent;
    animation: fadeBackgroundYellow 4s infinite;
  }
  
  @keyframes fadeBackgroundRed {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: rgb(206, 64, 64);
    }
    100% {
      background-color: transparent;
    }
  }
  
  @keyframes fadeBackgroundYellow {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: rgb(216, 216, 33);
    }
    100% {
      background-color: transparent;
    }
  }
  .ARCADENOE>.leading {
    background-color:rgba(74, 160, 44, .2)!important
}
.AmazonCACanada>.leading {
    background-color:rgba(65, 163, 23, .2)!important
}
.CertcoInc>.leading {
    background-color:rgba(74, 160, 44, .2)!important
}
.ChewyONLINE>.leading {
    background-color:rgba(139, 179, 129, .2)!important
}
.GROCERYOUTLET>.leading {
    background-color:rgba(153, 198, 142, .2)!important
}
.OLLIESBARGAINOUTLET>.leading {
    background-color:rgba(76, 196, 23, .2)!important
}
.PETSENSE>.leading {
    background-color:rgba(108, 196, 23, .2)!important
}
.TUESDAYMORNING>.leading {
    background-color:rgba(82, 208, 23, .2)!important
}
.SpreetailLLC>.leading {
    background-color:rgba(76, 197, 82, .2)!important
}
.AMAZONCOMAZDCINC>.leading {
    background-color:rgba(84, 197, 113, .2)!important
}
.BaxterBoo>.leading {
    background-color:rgba(87, 233, 100, .2)!important
}
.Coupaw>.leading {
    background-color:rgba(94, 251, 110, .2)!important
}
.Cuddlycom>.leading {
    background-color:rgba(100, 233, 134, .2)!important
}
.DailyStealsONLINE>.leading {
    background-color:rgba(106, 251, 146, .2)!important
}
.DOGGYLOOTLLC>.leading {
    background-color:rgba(181, 234, 170, .2)!important
}
.Ebay>.leading {
    background-color:rgba(195, 253, 184, .2)!important
}
.Facebook>.leading {
    background-color:rgba(0, 255, 0, .2)!important
}
.Google>.leading {
    background-color:rgba(135, 247, 23, .2)!important
}
.Groupon>.leading {
    background-color:rgba(95, 251, 23, .2)!important
}
.Hayneedle>.leading {
    background-color:rgba(89, 232, 23, .2)!important
}
.HealthyPetscom>.leading {
    background-color:rgba(127, 232, 23, .2)!important
}
.ISLANDPETCENTER>.leading {
    background-color:rgba(138, 251, 23, .2)!important
}
.JClub>.leading {
    background-color:rgba(177, 251, 23, .2)!important
}
.Newegg>.leading {
    background-color:rgba(204, 251, 93, .2)!important
}
.NIMBLECOMMERCE>.leading {
    background-color:rgba(188, 233, 84, .2)!important
}
.Offerscom>.leading {
    background-color:rgba(160, 197, 68, .2)!important
}
.OpenSky>.leading {
    background-color:rgba(255, 255, 0, .2)!important
}
.Overstockcom>.leading {
    background-color:rgba(255, 252, 23, .2)!important
}
.Peazz>.leading {
    background-color:rgba(255, 243, 128, .2)!important
}
.Rakuten>.leading {
    background-color:rgba(237, 226, 117, .2)!important
}
.Sears>.leading {
    background-color:rgba(237, 218, 116, .2)!important
}
.UnbeatableSalecom>.leading {
    background-color:rgba(234, 193, 23, .2)!important
}
.UntilGonecom>.leading {
    background-color:rgba(253, 208, 23, .2)!important
}
.WalmartMarketplace>.leading {
    background-color:rgba(251, 185, 23, .2)!important
}
.WOOT>.leading {
    background-color:rgba(233, 171, 23, .2)!important
}
.WayfairCanada>.leading {
    background-color:rgba(212, 160, 23, .2)!important
}
.CastleGate>.leading {
    background-color:rgba(199, 163, 23, .2)!important
}
.Tanga>.leading {
    background-color:rgba(198, 142, 23, .2)!important
}
.Wishcom>.leading {
    background-color:rgba(175, 120, 23, .2)!important
}
.CONSUMERCOMPONENTORDER>.leading {
    background-color:rgba(173, 169, 110, .2)!important
}
.EmployeeSales>.leading {
    background-color:rgba(201, 190, 98, .2)!important
}
.FurhavenStore>.leading {
    background-color:rgba(130, 120, 57, .2)!important
}
.Promo>.leading {
    background-color:rgba(251, 177, 23, .2)!important
}
.SOCIALSAMPLES>.leading {
    background-color:rgba(232, 163, 23, .2)!important
}
.TRADESHOWORDERSSAMPLES>.leading {
    background-color:rgba(197, 137, 23, .2)!important
}
.WARRANTYREPLACEMENT>.leading {
    background-color:rgba(248, 116, 49, .2)!important
}
.GrouponCanada>.leading {
    background-color:rgba(230, 108, 44, .2)!important
}
.Houzz>.leading {
    background-color:rgba(248, 128, 23, .2)!important
}
.Petco>.leading {
    background-color:rgba(248, 114, 23, .2)!important
}
.TractorSupply>.leading {
    background-color:rgba(229, 103, 23, .2)!important
}
.WalmartDSV>.leading {
    background-color:rgba(195, 86, 23, .2)!important
}
.ZULILYDROPSHIP>.leading {
    background-color:rgba(195, 88, 23, .2)!important
}
.AmazonDirectFulfillment>.leading {
    background-color:rgba(138, 65, 23, .2)!important
}
.AmazonSellerCentralDropShip>.leading {
    background-color:rgba(126, 53, 23, .2)!important
}
.ChewyDropship>.leading {
    background-color:rgba(126, 34, 23, .2)!important
}
.Wayfair>.leading {
    background-color:rgba(126, 49, 23, .2)!important
}
.GROUPONNEEDISHLTD>.leading {
    background-color:rgba(126, 56, 23, .2)!important
}
.AWBROWNPETGARDEN>.leading {
    background-color:rgba(127, 82, 23, .2)!important
}
.ADOPTSHOP>.leading {
    background-color:rgba(128, 101, 23, .2)!important
}
.ALLPETEQUINESUPPLY>.leading {
    background-color:rgba(128, 88, 23, .2)!important
}
.AMENITYSERVICES>.leading {
    background-color:rgba(127, 70, 44, .2)!important
}
.BLUERIDGEPETSUPPLY>.leading {
    background-color:rgba(200, 90, 23, .2)!important
}
.BRAXTONSANIMALWORKS>.leading {
    background-color:rgba(195, 74, 44, .2)!important
}
.CHOICEPETSUPPLYCTCORP>.leading {
    background-color:rgba(229, 91, 60, .2)!important
}
.CHOWDOWNCOMONTROSE>.leading {
    background-color:rgba(247, 101, 65, .2)!important
}
.COLDSPOTFEEDS>.leading {
    background-color:rgba(225, 139, 107, .2)!important
}
.COUNTRYFEEDSTORE>.leading {
    background-color:rgba(248, 129, 88, .2)!important
}
.CREATURECOMFORTS>.leading {
    background-color:rgba(230, 116, 81, .2)!important
}
.DOGLOVERSofTarpon>.leading {
    background-color:rgba(195, 98, 65, .2)!important
}
.DUNHAMSSPORTS>.leading {
    background-color:rgba(196, 116, 81, .2)!important
}
.ELLIOTTSFORPETS>.leading {
    background-color:rgba(231, 138, 97, .2)!important
}
.FAIRWOODPETCENTER>.leading {
    background-color:rgba(249, 150, 107, .2)!important
}
.FAUXPAWS>.leading {
    background-color:rgba(238, 154, 77, .2)!important
}
.FeedersPetSupplyCompanyLLC>.leading {
    background-color:rgba(246, 96, 171, .2)!important
}
.FOSTERSFAMILYPETCENTER>.leading {
    background-color:rgba(246, 101, 171, .2)!important
}
.GONETOTHEDOGS>.leading {
    background-color:rgba(228, 94, 157, .2)!important
}
.GREENFIELDFARMERSCOOPEXCHANGE>.leading {
    background-color:rgba(194, 82, 131, .2)!important
}
.HEALTHYPETILAurora>.leading {
    background-color:rgba(125, 34, 82, .2)!important
}
.HIGHPAWSPETCOFAIRPLAY>.leading {
    background-color:rgba(231, 116, 113, .2)!important
}
.HOTELBELLWETHER>.leading {
    background-color:rgba(247, 93, 89, .2)!important
}
.JeffersPet>.leading {
    background-color:rgba(229, 84, 81, .2)!important
}
.KATIESPETDEPOTCORPORATESTORE>.leading {
    background-color:rgba(194, 70, 65, .2)!important
}
.LEESFEEDWESTERNSTORE>.leading {
    background-color:rgba(255, 0, 0, .2)!important
}
.MACSPETDEPOTWIMILWAUKEE>.leading {
    background-color:rgba(246, 34, 23, .2)!important
}
.MADCAT>.leading {
    background-color:rgba(228, 27, 23, .2)!important
}
.MAINSTREETPETPRODUCTS>.leading {
    background-color:rgba(246, 40, 23, .2)!important
}
.MARATHONTOWNCOUNTRYSTORE>.leading {
    background-color:rgba(228, 34, 23, .2)!important
}
.MISTYSPETDEPOT>.leading {
    background-color:rgba(193, 27, 23, .2)!important
}
.MITCHELLVETSUPPLY>.leading {
    background-color:rgba(250, 175, 190, .2)!important
}
.MOABBARKERY>.leading {
    background-color:rgba(251, 187, 185, .2)!important
}
.MOUNDSWICORPORATESUNPRAIRIE>.leading {
    background-color:rgba(232, 173, 170, .2)!important
}
.MOUNDSWIFITCHBURG>.leading {
    background-color:rgba(231, 161, 176, .2)!important
}
.MOUNDSWIMADISON>.leading {
    background-color:rgba(250, 175, 186, .2)!important
}
.MOUNDSWIMIDDLETON>.leading {
    background-color:rgba(249, 167, 176, .2)!important
}
.MOUNDSWIJANESVILLE>.leading {
    background-color:rgba(231, 153, 163, .2)!important
}
.NATURESEMPORIUMBESTPETROANOKE>.leading {
    background-color:rgba(196, 135, 147, .2)!important
}
.NATURESEMPORIUMBESTPETSBURLINGTON>.leading {
    background-color:rgba(197, 144, 142, .2)!important
}
.NEWENGLANDPETSUPPLY>.leading {
    background-color:rgba(179, 132, 129, .2)!important
}
.PETBARN>.leading {
    background-color:rgba(196, 129, 137, .2)!important
}
.PETDEPOTALFLORENCE>.leading {
    background-color:rgba(127, 90, 88, .2)!important
}
.PETFOODSPLUSBRISTOL>.leading {
    background-color:rgba(127, 78, 82, .2)!important
}
.PETFUN>.leading {
    background-color:rgba(127, 82, 93, .2)!important
}
.PETINTHECITYLLC>.leading {
    background-color:rgba(129, 118, 121, .2)!important
}
.PETKAREALLICAT>.leading {
    background-color:rgba(129, 115, 57, .2)!important
}
.PETNUTRITIONCENTERNANUET>.leading {
    background-color:rgba(130, 123, 96, .2)!important
}
.PETPALACEAURORACO>.leading {
    background-color:rgba(201, 194, 153, .2)!important
}
.PETPEOPLEOFLOSGATOS>.leading {
    background-color:rgba(200, 181, 96, .2)!important
}
.PETPLACEPLUS>.leading {
    background-color:rgba(236, 214, 114, .2)!important
}
.PETWAREHOUSE>.leading {
    background-color:rgba(236, 216, 114, .2)!important
}
.PETITEPETS>.leading {
    background-color:rgba(255, 232, 124, .2)!important
}
.PETPROSCORP>.leading {
    background-color:rgba(236, 229, 182, .2)!important
}
.PETSNSTUFF>.leading {
    background-color:rgba(255, 248, 198, .2)!important
}
.PETSSTORENEXTDOORCTBOLTON>.leading {
    background-color:rgba(250, 248, 204, .2)!important
}
.PLALMONT>.leading {
    background-color:rgba(21, 27, 141, .2)!important
}
.PLFLBRADENTON>.leading {
    background-color:rgba(21, 49, 126, .2)!important
}
.PLFLFTMYER>.leading {
    background-color:rgba(52, 45, 126, .2)!important
}
.PLFLFTWALT>.leading {
    background-color:rgba(43, 96, 222, .2)!important
}
.PLKSWESTWICHITA>.leading {
    background-color:rgba(48, 110, 255, .2)!important
}
.PLKSWICHITA>.leading {
    background-color:rgba(43, 101, 236, .2)!important
}
.PLTXKATY>.leading {
    background-color:rgba(37, 84, 199, .2)!important
}
.PLTXSANANTONIOLEONVALLEY>.leading {
    background-color:rgba(59, 185, 255, .2)!important
}
.PLTXSANANTONIOVINEYARD>.leading {
    background-color:rgba(56, 172, 236, .2)!important
}
.PREMIERPETSUPPLYLIVONIA>.leading {
    background-color:rgba(53, 126, 199, .2)!important
}
.PSPHURON>.leading {
    background-color:rgba(48, 144, 199, .2)!important
}
.RYANSPETSUPPLY>.leading {
    background-color:rgba(37, 88, 126, .2)!important
}
.SALEMPETSUPPLY>.leading {
    background-color:rgba(21, 137, 255, .2)!important
}
.SALUDARIVERPETBOILINGSPRINGS>.leading {
    background-color:rgba(21, 125, 236, .2)!important
}
.SALUDARIVERPETEASLEY>.leading {
    background-color:rgba(21, 105, 199, .2)!important
}
.SALUDARIVERPETGREENVILLE>.leading {
    background-color:rgba(21, 62, 126, .2)!important
}
.SALUDARIVERPETSIMPSONVILLE>.leading {
    background-color:rgba(43, 84, 126, .2)!important
}
.SANDYSPETFOODCENTER>.leading {
    background-color:rgba(72, 99, 160, .2)!important
}
.SEATTLEAREAFELINERESCUE>.leading {
    background-color:rgba(105, 96, 236, .2)!important
}
.SOLDANBAYCITY>.leading {
    background-color:rgba(141, 56, 201, .2)!important
}
.SOLDANCHARLOTTE>.leading {
    background-color:rgba(122, 93, 199, .2)!important
}
.SOLDANDEWITT>.leading {
    background-color:rgba(132, 103, 215, .2)!important
}
.SOLDANMIDLAND>.leading {
    background-color:rgba(145, 114, 236, .2)!important
}
.SOLDANMTPLEASANT>.leading {
    background-color:rgba(158, 123, 255, .2)!important
}
.SOLDANOKEMOS>.leading {
    background-color:rgba(114, 143, 206, .2)!important
}
.SOLDANSOUTHLANSING>.leading {
    background-color:rgba(72, 138, 199, .2)!important
}
.SOLDANWESTLANSING>.leading {
    background-color:rgba(86, 165, 236, .2)!important
}
.STAYTONANIMALSUPPLY>.leading {
    background-color:rgba(92, 179, 255, .2)!important
}
.SUTHERLANDSPETWORKSNY>.leading {
    background-color:rgba(101, 158, 199, .2)!important
}
.TAILWAGGERS>.leading {
    background-color:rgba(65, 98, 126, .2)!important
}
.TEDSFEEDSTORE>.leading {
    background-color:rgba(115, 124, 161, .2)!important
}
.TERRYSPETSTOP>.leading {
    background-color:rgba(152, 175, 199, .2)!important
}
.THATFISHPLACE>.leading {
    background-color:rgba(246, 53, 138, .2)!important
}
.THEAQUARIUMANDPETS>.leading {
    background-color:rgba(228, 49, 127, .2)!important
}
.THEBONEAPPETITE>.leading {
    background-color:rgba(245, 40, 135, .2)!important
}
.THEDOGHOUSE>.leading {
    background-color:rgba(228, 40, 124, .2)!important
}
.THEHOUNDSMEOW>.leading {
    background-color:rgba(193, 34, 103, .2)!important
}
.THEWHOLEPETARFORTSMITH>.leading {
    background-color:rgba(125, 5, 63, .2)!important
}
.THERESASCOUNTRYFEED>.leading {
    background-color:rgba(202, 34, 107, .2)!important
}
.VILLAGEPETSUPPLY>.leading {
    background-color:rgba(193, 40, 105, .2)!important
}
.WAGGINGTAILSNYFORESTHILLS>.leading {
    background-color:rgba(128, 5, 23, .2)!important
}
.WELOVPETSOHMTVERNON>.leading {
    background-color:rgba(125, 5, 65, .2)!important
}
.WELOVPETSOHMARIETTA>.leading {
    background-color:rgba(125, 5, 82, .2)!important
}
.WELOVPETSOHNEWPHILLY>.leading {
    background-color:rgba(129, 5, 65, .2)!important
}
.WELOVPETSOHZANESVILLE>.leading {
    background-color:rgba(193, 34, 131, .2)!important
}
.WETNOSES>.leading {
    background-color:rgba(227, 49, 157, .2)!important
}
.WHISKERBONESSUPPLYCO>.leading {
    background-color:rgba(245, 53, 170, .2)!important
}
.WHOLEPETSMARKETNY>.leading {
    background-color:rgba(255, 0, 255, .2)!important
}
.WHOLEPETSMARKETTXCORPUSCHRISTI>.leading {
    background-color:rgba(244, 51, 255, .2)!important
}
.WHOLEPETSMARKETTXBASTROP>.leading {
    background-color:rgba(226, 56, 236, .2)!important
}
.WHOLEPETSMARKETTXDRIPPINGSPRINGS>.leading {
    background-color:rgba(192, 49, 199, .2)!important
}
.WHOLEPETSMARKETTXGEORGETOWN>.leading {
    background-color:rgba(176, 72, 181, .2)!important
}
.WHOLEPETSMARKETTXLAGOVISTA>.leading {
    background-color:rgba(212, 98, 255, .2)!important
}
.WOOFSDOGBAKERYHARBOR>.leading {
    background-color:rgba(196, 90, 236, .2)!important
}
.TractorSupplyCompany>.leading {
    background-color:rgba(167, 74, 199, .2)!important
}
.THEFEEDBARN>.leading {
    background-color:rgba(106, 40, 126, .2)!important
}
.KCATERERSNY>.leading {
    background-color:rgba(142, 53, 239, .2)!important
}
.PETSETCOFVERMONT>.leading {
    background-color:rgba(137, 59, 255, .2)!important
}
.TargetCorp>.leading {
    background-color:rgba(127, 56, 236, .2)!important
}
.AmazonPerformanceChargebacks>.leading {
    background-color:rgba(108, 45, 199, .2)!important
}
.Lowes>.leading {
    background-color:rgba(70, 27, 126, .2)!important
}
.GreaterGood>.leading {
    background-color:rgba(87, 27, 126, .2)!important
}
.ANDERSONVALLEYFARMSUPPLY>.leading {
    background-color:rgba(125, 27, 126, .2)!important
}
.Petsmart>.leading {
    background-color:rgba(132, 45, 206, .2)!important
}
.CHERRYVALLEYFEEDSUPPLIES>.leading {
    background-color:rgba(139, 49, 199, .2)!important
}
.DAVESODASPETCITYLUDLOW>.leading {
    background-color:rgba(162, 59, 236, .2)!important
}
.DoggyLootLLC>.leading {
    background-color:rgba(176, 65, 255, .2)!important
}
.DONATIONS>.leading {
    background-color:rgba(126, 88, 126, .2)!important
}
.ETHICALPAWS>.leading {
    background-color:rgba(209, 101, 135, .2)!important
}
.Etsy>.leading {
    background-color:rgba(247, 120, 161, .2)!important
}
.HUGGYBEARSPETMARKET>.leading {
    background-color:rgba(229, 110, 148, .2)!important
}
.KATIESPETDEPOTLOSANGLESCA>.leading {
    background-color:rgba(194, 90, 124, .2)!important
}
.MRMOCHASPETSUPPLY>.leading {
    background-color:rgba(126, 53, 77, .2)!important
}
.NATURESEMPORIUMTheNaturalDog>.leading {
    background-color:rgba(185, 59, 143, .2)!important
}
.NATURESPETMARKETFIVEOAKS>.leading {
    background-color:rgba(249, 183, 255, .2)!important
}
.OUTFRONTFARMPETSUPPLY>.leading {
    background-color:rgba(230, 169, 236, .2)!important
}
.PAMPEREDPAWSPETSALON>.leading {
    background-color:rgba(195, 142, 199, .2)!important
}
.PATTISPETDEPOTHOUSTONTX>.leading {
    background-color:rgba(210, 185, 211, .2)!important
}
.PETAPOLUZAPETSUPPLY>.leading {
    background-color:rgba(198, 174, 199, .2)!important
}
.NCMPETSLLCDBAPETSCHOICE>.leading {
    background-color:rgba(235, 221, 226, .2)!important
}
.PETSNATURALLY>.leading {
    background-color:rgba(200, 187, 190, .2)!important
}
.PLOHCorporate>.leading {
    background-color:rgba(233, 207, 236, .2)!important
}
.PREMIERPETSUPPLYBH>.leading {
    background-color:rgba(252, 223, 255, .2)!important
}
.PSP>.leading {
    background-color:rgba(227, 228, 250, .2)!important
}
.PSP>.leading {
    background-color:rgba(253, 238, 244, .2)!important
}
.PSP>.leading {
    background-color:rgba(198, 222, 255, .2)!important
}
.SMITHLANDSUPPLY>.leading {
    background-color:rgba(173, 223, 255, .2)!important
}
.SPRINGCREEKANIMALHOSPITAL>.leading {
    background-color:rgba(189, 237, 255, .2)!important
}
.THEHEALTHYPETFREELAND>.leading {
    background-color:rgba(224, 255, 255, .2)!important
}
.THEPETWORKS>.leading {
    background-color:rgba(194, 223, 255, .2)!important
}
.THEWHOLEPETARSPRINGDALE>.leading {
    background-color:rgba(180, 207, 236, .2)!important
}
.WESTPLAINSVETSUPPLY>.leading {
    background-color:rgba(183, 206, 236, .2)!important
}
.ZULILYBULK>.leading {
    background-color:rgba(82, 243, 255, .2)!important
}
.SweetDeals>.leading {
    background-color:rgba(0, 255, 255, .2)!important
}
.SweetDeals>.leading {
    background-color:rgba(87, 254, 255, .2)!important
}
.UKUSCAdoggie>.leading {
    background-color:rgba(80, 235, 236, .2)!important
}
.LIGONIERPAWSONMAIN>.leading {
    background-color:rgba(78, 226, 236, .2)!important
}
.PREMIERPETSUPPLYNOVI>.leading {
    background-color:rgba(72, 204, 205, .2)!important
}
.PREMIERPETSUPPLYNORTHVILLE>.leading {
    background-color:rgba(67, 198, 219, .2)!important
}
.ANIMALKRACKERS>.leading {
    background-color:rgba(154, 254, 255, .2)!important
}
.CHOICEDOGPRODUCTS>.leading {
    background-color:rgba(142, 235, 236, .2)!important
}
.ALLABOUTDOGSCATS>.leading {
    background-color:rgba(120, 199, 199, .2)!important
}
.AdvancedFoamRecycling>.leading {
    background-color:rgba(70, 199, 199, .2)!important
}
.ALWAYSESSENTIALDOGS>.leading {
    background-color:rgba(67, 191, 199, .2)!important
}
.WALMARTSTORESINC>.leading {
    background-color:rgba(119, 191, 199, .2)!important
}
.LONGARMTECHNOLOGIESLLC>.leading {
    background-color:rgba(146, 199, 199, .2)!important
}
.SUNDANCEKENNELSINC>.leading {
    background-color:rgba(175, 220, 236, .2)!important
}
.RICHMONDFEEDSERVICEINC>.leading {
    background-color:rgba(59, 156, 156, .2)!important
}
.DAYPETSUPPLY>.leading {
    background-color:rgba(48, 125, 126, .2)!important
}
.AcademySportsOutdoors>.leading {
    background-color:rgba(62, 169, 159, .2)!important
}
.WAGSWALKS>.leading {
    background-color:rgba(130, 202, 250, .2)!important
}
.RELIANTATLANTICGROUP>.leading {
    background-color:rgba(160, 207, 236, .2)!important
}
.THEFEEDBAGPETSUPPLYCOMPANY>.leading {
    background-color:rgba(135, 175, 199, .2)!important
}
.SandyPawsRescueInc>.leading {
    background-color:rgba(130, 202, 255, .2)!important
}
.PetFoodWarehouse>.leading {
    background-color:rgba(121, 186, 236, .2)!important
}
.MidwestVeterinaryPartnersdbaSandRoad>.leading {
    background-color:rgba(86, 109, 126, .2)!important
}
.HometownAnimalSupplyLLC>.leading {
    background-color:rgba(102, 152, 255, .2)!important
}
.SmartSourceLLC>.leading {
    background-color:rgba(115, 106, 255, .2)!important
}
.AmazonSellerCentralBulk>.leading {
    background-color:rgba(207, 236, 236, .2)!important
}
.WagPride>.leading {
    background-color:rgba(175, 199, 199, .2)!important
}
.HappyTailsTravelInc>.leading {
    background-color:rgba(113, 125, 125, .2)!important
}
.TouchofModern>.leading {
    background-color:rgba(149, 185, 199, .2)!important
}
.KrogerdbaVitacostcom>.leading {
    background-color:rgba(109, 105, 104, .2)!important
}
.PeapodADUSACommercialHoldingsInc>.leading {
    background-color:rgba(110, 106, 107, .2)!important
}
.FaireWholesaleInc>.leading {
    background-color:rgba(114, 110, 109, .2)!important
}
.CoastalConservationAssociationInc>.leading {
    background-color:rgba(116, 113, 112, .2)!important
}
.BestFriendsPetHotel>.leading {
    background-color:rgba(115, 111, 110, .2)!important
}
.BarkgataPetHotelSpa>.leading {
    background-color:rgba(97, 109, 126, .2)!important
}
.PaperRecyclingServices>.leading {
    background-color:rgba(101, 115, 131, .2)!important
}
.TecaTuAPawsworthyPetEmporium>.leading {
    background-color:rgba(100, 109, 126, .2)!important
}
.HOWLISTICLLC>.leading {
    background-color:rgba(109, 123, 141, .2)!important
}
.TexasverseLLC>.leading {
    background-color:rgba(76, 120, 126, .2)!important
}
.BargainHunt>.leading {
    background-color:rgba(76, 125, 126, .2)!important
}
.BeverlysPetCenter>.leading {
    background-color:rgba(128, 109, 126, .2)!important
}
.BigLots>.leading {
    background-color:rgba(94, 90, 128, .2)!important
}
.LincolnRecycling>.leading {
    background-color:rgba(78, 56, 126, .2)!important
}
.EssexTechnologyGroupLLCDBABargainHunt>.leading {
    background-color:rgba(21, 27, 84, .2)!important
}
.PetsEtcofVermont>.leading {
    background-color:rgba(43, 56, 86, .2)!important
}
.PetParentsStore>.leading {
    background-color:rgba(37, 56, 60, .2)!important
}
.PICAPETIncdbaPetsNStuff>.leading {
    background-color:rgba(70, 62, 65, .2)!important
}
.MartillaLLC>.leading {
    background-color:rgba(113, 125, 125, .2)!important
}
.PetSupermarketInc>.leading {
    background-color:rgba(149, 185, 199, .2)!important
}
.WesternPetSupply>.leading {
    background-color:rgba(94, 118, 126, .2)!important
}
.AmazonDirectImport>.leading {
    background-color:rgba(94, 125, 126, .2)!important
}
.ParkerPetDBAVeterinaryMedicalCnt>.leading {
    background-color:rgba(97, 124, 88, .2)!important
}
.WWBazaarVoiceSamples>.leading {
    background-color:rgba(52, 135, 129, .2)!important
}
.CentralGardenandPetdbaCentralPetDistribution>.leading {
    background-color:rgba(48, 103, 84, .2)!important
}
.CUDDLYBULK>.leading {
    background-color:rgba(78, 137, 117, .2)!important
}
.PetsensebyTractorSupply>.leading {
    background-color:rgba(37, 65, 23, .2)!important
}
.VRCompaniesLLCDBAYourPetsNaturallyCascade>.leading {
    background-color:rgba(56, 124, 68, .2)!important
}
.PatsPetsofMishawaka>.leading {
    background-color:rgba(181, 234, 170, .2)!important
}
.PREMIERPETSUPPLYDETROIT>.leading {
    background-color:rgba(195, 253, 184, .2)!important
}
.MIDPRODUCTIONLLC>.leading {
    background-color:rgba(0, 255, 0, .2)!important
}
.KKVetSupply>.leading {
    background-color:rgba(135, 247, 23, .2)!important
}
.MPJNSHELBYINCdbaPremierPetSupply>.leading {
    background-color:rgba(95, 251, 23, .2)!important
}
.SALESSAMPLES>.leading {
    background-color:rgba(89, 232, 23, .2)!important
}
.SavvyPetParadise>.leading {
    background-color:rgba(127, 232, 23, .2)!important
}
.WorldWisePetco>.leading {
    background-color:rgba(138, 251, 23, .2)!important
}
.RazorThinkForecast>.leading {
    background-color:rgba(177, 251, 23, .2)!important
}
.AMAZONVendorFlex>.leading {
    background-color:rgba(204, 251, 93, .2)!important
}
.CentralGardenandPetDBAGeneralPetSupply>.leading {
    background-color:rgba(188, 233, 84, .2)!important
}
.PeyIncDBAPetExpoDistibuters>.leading {
    background-color:rgba(160, 197, 68, .2)!important
}
.UPCOPetSupplies>.leading {
    background-color:rgba(255, 255, 0, .2)!important
}
.WorldWiseLowes>.leading {
    background-color:rgba(255, 252, 23, .2)!important
}
.PamperedPupLLC>.leading {
    background-color:rgba(255, 243, 128, .2)!important
}
.HomeGoodsInc>.leading {
    background-color:rgba(237, 226, 117, .2)!important
}
.SierraTradingPostInc>.leading {
    background-color:rgba(237, 218, 116, .2)!important
}
.TJXCanadaDBAHomesense>.leading {
    background-color:rgba(234, 193, 23, .2)!important
}
.AmazonCanadaDirectImport>.leading {
    background-color:rgba(253, 208, 23, .2)!important
}
.AmazonMexicoDirectImport>.leading {
    background-color:rgba(251, 185, 23, .2)!important
}
.OceanStateJobbers>.leading {
    background-color:rgba(233, 171, 23, .2)!important
}
.PetFactoryHyVee>.leading {
    background-color:rgba(212, 160, 23, .2)!important
}
.TJXCompaniesIncdbaTJMaxx>.leading {
    background-color:rgba(199, 163, 23, .2)!important
}
.IMPORTADORAMEXICANADEMASCOTASdbaPETCOMEXICO>.leading {
    background-color:rgba(198, 142, 23, .2)!important
}
.MidStatesDistributingLLC>.leading {
    background-color:rgba(175, 120, 23, .2)!important
}
.ChewyPrivateLabel>.leading {
    background-color:rgba(173, 169, 110, .2)!important
}
.MarshallsofMAInc>.leading {
    background-color:rgba(201, 190, 98, .2)!important
}
.CalmComfyKY>.leading {
    background-color:rgba(130, 120, 57, .2)!important
}
.GordmansStoresBadDebtRecovery>.leading {
    background-color:rgba(251, 177, 23, .2)!important
}
.PREBUILDNOSALE>.leading {
    background-color:rgba(232, 163, 23, .2)!important
}
.ZhejiangKaifengImportExportCoLTD>.leading {
    background-color:rgba(197, 137, 23, .2)!important
}
.WorldWise>.leading {
    background-color:rgba(248, 116, 49, .2)!important
}
.PetFactory>.leading {
    background-color:rgba(255, 252, 23, .2)!important
}
.RKIAppsIncDBASocialLadder>.leading {
    background-color:rgba(255, 243, 128, .2)!important
}


.ARCADENOE {
  background-color:rgba(74, 160, 44, 0.2)
}
.AmazonCACanada {
  background-color:rgba(65, 163, 23, 0.2)
}
.CertcoInc {
  background-color:rgba(74, 160, 44, 0.2)
}
.ChewyONLINE {
  background-color:rgba(139, 179, 129, 0.2)
}
.GROCERYOUTLET {
  background-color:rgba(153, 198, 142, 0.2)
}
.OLLIESBARGAINOUTLET {
  background-color:rgba(76, 196, 23, 0.2)
}
.PETSENSE {
  background-color:rgba(108, 196, 23, 0.2)
}
.TUESDAYMORNING {
  background-color:rgba(82, 208, 23, 0.2)
}
.SpreetailLLC {
  background-color:rgba(76, 197, 82, 0.2)
}
.AMAZONCOMAZDCINC {
  background-color:rgba(84, 197, 113, 0.2)
}
.BaxterBoo {
  background-color:rgba(87, 233, 100, 0.2)
}
.Coupaw {
  background-color:rgba(94, 251, 110, 0.2)
}
.Cuddlycom {
  background-color:rgba(100, 233, 134, 0.2)
}
.DailyStealsONLINE {
  background-color:rgba(106, 251, 146, 0.2)
}
.DOGGYLOOTLLC {
  background-color:rgba(181, 234, 170, 0.2)
}
.Ebay {
  background-color:rgba(195, 253, 184, 0.2)
}
.Facebook {
  background-color:rgba(0, 255, 0, 0.2)
}
.Google {
  background-color:rgba(135, 247, 23, 0.2)
}
.Groupon {
  background-color:rgba(95, 251, 23, 0.2)
}
.Hayneedle {
  background-color:rgba(89, 232, 23, 0.2)
}
.HealthyPetscom {
  background-color:rgba(127, 232, 23, 0.2)
}
.ISLANDPETCENTER {
  background-color:rgba(138, 251, 23, 0.2)
}
.JClub {
  background-color:rgba(177, 251, 23, 0.2)
}
.Newegg {
  background-color:rgba(204, 251, 93, 0.2)
}
.NIMBLECOMMERCE {
  background-color:rgba(188, 233, 84, 0.2)
}
.Offerscom {
  background-color:rgba(160, 197, 68, 0.2)
}
.OpenSky {
  background-color:rgba(255, 255, 0, 0.2)
}
.Overstockcom {
  background-color:rgba(255, 252, 23, 0.2)
}
.Peazz {
  background-color:rgba(255, 243, 128, 0.2)
}
.Rakuten {
  background-color:rgba(237, 226, 117, 0.2)
}
.Sears {
  background-color:rgba(237, 218, 116, 0.2)
}
.UnbeatableSalecom {
  background-color:rgba(234, 193, 23, 0.2)
}
.UntilGonecom {
  background-color:rgba(253, 208, 23, 0.2)
}
.WalmartMarketplace {
  background-color:rgba(251, 185, 23, 0.2)
}
.WOOT {
  background-color:rgba(233, 171, 23, 0.2)
}
.WayfairCanada {
  background-color:rgba(212, 160, 23, 0.2)
}
.CastleGate {
  background-color:rgba(199, 163, 23, 0.2)
}
.Tanga {
  background-color:rgba(198, 142, 23, 0.2)
}
.Wishcom {
  background-color:rgba(175, 120, 23, 0.2)
}
.CONSUMERCOMPONENTORDER {
  background-color:rgba(173, 169, 110, 0.2)
}
.EmployeeSales {
  background-color:rgba(201, 190, 98, 0.2)
}
.FurhavenStore {
  background-color:rgba(130, 120, 57, 0.2)
}
.Promo {
  background-color:rgba(251, 177, 23, 0.2)
}
.SOCIALSAMPLES {
  background-color:rgba(232, 163, 23, 0.2)
}
.TRADESHOWORDERSSAMPLES {
  background-color:rgba(197, 137, 23, 0.2)
}
.WARRANTYREPLACEMENT {
  background-color:rgba(248, 116, 49, 0.2)
}
.GrouponCanada {
  background-color:rgba(230, 108, 44, 0.2)
}
.Houzz {
  background-color:rgba(248, 128, 23, 0.2)
}
.Petco {
  background-color:rgba(248, 114, 23, 0.2)
}
.TractorSupply {
  background-color:rgba(229, 103, 23, 0.2)
}
.WalmartDSV {
  background-color:rgba(195, 86, 23, 0.2)
}
.ZULILYDROPSHIP {
  background-color:rgba(195, 88, 23, 0.2)
}
.AmazonDirectFulfillment {
  background-color:rgba(138, 65, 23, 0.2)
}
.AmazonSellerCentralDropShip {
  background-color:rgba(126, 53, 23, 0.2)
}
.ChewyDropship {
  background-color:rgba(126, 34, 23, 0.2)
}
.Wayfair {
  background-color:rgba(126, 49, 23, 0.2)
}
.GROUPONNEEDISHLTD {
  background-color:rgba(126, 56, 23, 0.2)
}
.AWBROWNPETGARDEN {
  background-color:rgba(127, 82, 23, 0.2)
}
.ADOPTSHOP {
  background-color:rgba(128, 101, 23, 0.2)
}
.ALLPETEQUINESUPPLY {
  background-color:rgba(128, 88, 23, 0.2)
}
.AMENITYSERVICES {
  background-color:rgba(127, 70, 44, 0.2)
}
.BLUERIDGEPETSUPPLY {
  background-color:rgba(200, 90, 23, 0.2)
}
.BRAXTONSANIMALWORKS {
  background-color:rgba(195, 74, 44, 0.2)
}
.CHOICEPETSUPPLYCTCORP {
  background-color:rgba(229, 91, 60, 0.2)
}
.CHOWDOWNCOMONTROSE {
  background-color:rgba(247, 101, 65, 0.2)
}
.COLDSPOTFEEDS {
  background-color:rgba(225, 139, 107, 0.2)
}
.COUNTRYFEEDSTORE {
  background-color:rgba(248, 129, 88, 0.2)
}
.CREATURECOMFORTS {
  background-color:rgba(230, 116, 81, 0.2)
}
.DOGLOVERSofTarpon {
  background-color:rgba(195, 98, 65, 0.2)
}
.DUNHAMSSPORTS {
  background-color:rgba(196, 116, 81, 0.2)
}
.ELLIOTTSFORPETS {
  background-color:rgba(231, 138, 97, 0.2)
}
.FAIRWOODPETCENTER {
  background-color:rgba(249, 150, 107, 0.2)
}
.FAUXPAWS {
  background-color:rgba(238, 154, 77, 0.2)
}
.FeedersPetSupplyCompanyLLC {
  background-color:rgba(246, 96, 171, 0.2)
}
.FOSTERSFAMILYPETCENTER {
  background-color:rgba(246, 101, 171, 0.2)
}
.GONETOTHEDOGS {
  background-color:rgba(228, 94, 157, 0.2)
}
.GREENFIELDFARMERSCOOPEXCHANGE {
  background-color:rgba(194, 82, 131, 0.2)
}
.HEALTHYPETILAurora {
  background-color:rgba(125, 34, 82, 0.2)
}
.HIGHPAWSPETCOFAIRPLAY {
  background-color:rgba(231, 116, 113, 0.2)
}
.HOTELBELLWETHER {
  background-color:rgba(247, 93, 89, 0.2)
}
.JeffersPet {
  background-color:rgba(229, 84, 81, 0.2)
}
.KATIESPETDEPOTCORPORATESTORE {
  background-color:rgba(194, 70, 65, 0.2)
}
.LEESFEEDWESTERNSTORE {
  background-color:rgba(255, 0, 0, 0.2)
}
.MACSPETDEPOTWIMILWAUKEE {
  background-color:rgba(246, 34, 23, 0.2)
}
.MADCAT {
  background-color:rgba(228, 27, 23, 0.2)
}
.MAINSTREETPETPRODUCTS {
  background-color:rgba(246, 40, 23, 0.2)
}
.MARATHONTOWNCOUNTRYSTORE {
  background-color:rgba(228, 34, 23, 0.2)
}
.MISTYSPETDEPOT {
  background-color:rgba(193, 27, 23, 0.2)
}
.MITCHELLVETSUPPLY {
  background-color:rgba(250, 175, 190, 0.2)
}
.MOABBARKERY {
  background-color:rgba(251, 187, 185, 0.2)
}
.MOUNDSWICORPORATESUNPRAIRIE {
  background-color:rgba(232, 173, 170, 0.2)
}
.MOUNDSWIFITCHBURG {
  background-color:rgba(231, 161, 176, 0.2)
}
.MOUNDSWIMADISON {
  background-color:rgba(250, 175, 186, 0.2)
}
.MOUNDSWIMIDDLETON {
  background-color:rgba(249, 167, 176, 0.2)
}
.MOUNDSWIJANESVILLE {
  background-color:rgba(231, 153, 163, 0.2)
}
.NATURESEMPORIUMBESTPETROANOKE {
  background-color:rgba(196, 135, 147, 0.2)
}
.NATURESEMPORIUMBESTPETSBURLINGTON {
  background-color:rgba(197, 144, 142, 0.2)
}
.NEWENGLANDPETSUPPLY {
  background-color:rgba(179, 132, 129, 0.2)
}
.PETBARN {
  background-color:rgba(196, 129, 137, 0.2)
}
.PETDEPOTALFLORENCE {
  background-color:rgba(127, 90, 88, 0.2)
}
.PETFOODSPLUSBRISTOL {
  background-color:rgba(127, 78, 82, 0.2)
}
.PETFUN {
  background-color:rgba(127, 82, 93, 0.2)
}
.PETINTHECITYLLC {
  background-color:rgba(129, 118, 121, 0.2)
}
.PETKAREALLICAT {
  background-color:rgba(129, 115, 57, 0.2)
}
.PETNUTRITIONCENTERNANUET {
  background-color:rgba(130, 123, 96, 0.2)
}
.PETPALACEAURORACO {
  background-color:rgba(201, 194, 153, 0.2)
}
.PETPEOPLEOFLOSGATOS {
  background-color:rgba(200, 181, 96, 0.2)
}
.PETPLACEPLUS {
  background-color:rgba(236, 214, 114, 0.2)
}
.PETWAREHOUSE {
  background-color:rgba(236, 216, 114, 0.2)
}
.PETITEPETS {
  background-color:rgba(255, 232, 124, 0.2)
}
.PETPROSCORP {
  background-color:rgba(236, 229, 182, 0.2)
}
.PETSNSTUFF {
  background-color:rgba(255, 248, 198, 0.2)
}
.PETSSTORENEXTDOORCTBOLTON {
  background-color:rgba(250, 248, 204, 0.2)
}
.PLALMONT {
  background-color:rgba(21, 27, 141, 0.2)
}
.PLFLBRADENTON {
  background-color:rgba(21, 49, 126, 0.2)
}
.PLFLFTMYER {
  background-color:rgba(52, 45, 126, 0.2)
}
.PLFLFTWALT {
  background-color:rgba(43, 96, 222, 0.2)
}
.PLKSWESTWICHITA {
  background-color:rgba(48, 110, 255, 0.2)
}
.PLKSWICHITA {
  background-color:rgba(43, 101, 236, 0.2)
}
.PLTXKATY {
  background-color:rgba(37, 84, 199, 0.2)
}
.PLTXSANANTONIOLEONVALLEY {
  background-color:rgba(59, 185, 255, 0.2)
}
.PLTXSANANTONIOVINEYARD {
  background-color:rgba(56, 172, 236, 0.2)
}
.PREMIERPETSUPPLYLIVONIA {
  background-color:rgba(53, 126, 199, 0.2)
}
.PSPHURON {
  background-color:rgba(48, 144, 199, 0.2)
}
.RYANSPETSUPPLY {
  background-color:rgba(37, 88, 126, 0.2)
}
.SALEMPETSUPPLY {
  background-color:rgba(21, 137, 255, 0.2)
}
.SALUDARIVERPETBOILINGSPRINGS {
  background-color:rgba(21, 125, 236, 0.2)
}
.SALUDARIVERPETEASLEY {
  background-color:rgba(21, 105, 199, 0.2)
}
.SALUDARIVERPETGREENVILLE {
  background-color:rgba(21, 62, 126, 0.2)
}
.SALUDARIVERPETSIMPSONVILLE {
  background-color:rgba(43, 84, 126, 0.2)
}
.SANDYSPETFOODCENTER {
  background-color:rgba(72, 99, 160, 0.2)
}
.SEATTLEAREAFELINERESCUE {
  background-color:rgba(105, 96, 236, 0.2)
}
.SOLDANBAYCITY {
  background-color:rgba(141, 56, 201, 0.2)
}
.SOLDANCHARLOTTE {
  background-color:rgba(122, 93, 199, 0.2)
}
.SOLDANDEWITT {
  background-color:rgba(132, 103, 215, 0.2)
}
.SOLDANMIDLAND {
  background-color:rgba(145, 114, 236, 0.2)
}
.SOLDANMTPLEASANT {
  background-color:rgba(158, 123, 255, 0.2)
}
.SOLDANOKEMOS {
  background-color:rgba(114, 143, 206, 0.2)
}
.SOLDANSOUTHLANSING {
  background-color:rgba(72, 138, 199, 0.2)
}
.SOLDANWESTLANSING {
  background-color:rgba(86, 165, 236, 0.2)
}
.STAYTONANIMALSUPPLY {
  background-color:rgba(92, 179, 255, 0.2)
}
.SUTHERLANDSPETWORKSNY {
  background-color:rgba(101, 158, 199, 0.2)
}
.TAILWAGGERS {
  background-color:rgba(65, 98, 126, 0.2)
}
.TEDSFEEDSTORE {
  background-color:rgba(115, 124, 161, 0.2)
}
.TERRYSPETSTOP {
  background-color:rgba(152, 175, 199, 0.2)
}
.THATFISHPLACE {
  background-color:rgba(246, 53, 138, 0.2)
}
.THEAQUARIUMANDPETS {
  background-color:rgba(228, 49, 127, 0.2)
}
.THEBONEAPPETITE {
  background-color:rgba(245, 40, 135, 0.2)
}
.THEDOGHOUSE {
  background-color:rgba(228, 40, 124, 0.2)
}
.THEHOUNDSMEOW {
  background-color:rgba(193, 34, 103, 0.2)
}
.THEWHOLEPETARFORTSMITH {
  background-color:rgba(125, 5, 63, 0.2)
}
.THERESASCOUNTRYFEED {
  background-color:rgba(202, 34, 107, 0.2)
}
.VILLAGEPETSUPPLY {
  background-color:rgba(193, 40, 105, 0.2)
}
.WAGGINGTAILSNYFORESTHILLS {
  background-color:rgba(128, 5, 23, 0.2)
}
.WELOVPETSOHMTVERNON {
  background-color:rgba(125, 5, 65, 0.2)
}
.WELOVPETSOHMARIETTA {
  background-color:rgba(125, 5, 82, 0.2)
}
.WELOVPETSOHNEWPHILLY {
  background-color:rgba(129, 5, 65, 0.2)
}
.WELOVPETSOHZANESVILLE {
  background-color:rgba(193, 34, 131, 0.2)
}
.WETNOSES {
  background-color:rgba(227, 49, 157, 0.2)
}
.WHISKERBONESSUPPLYCO {
  background-color:rgba(245, 53, 170, 0.2)
}
.WHOLEPETSMARKETNY {
  background-color:rgba(255, 0, 255, 0.2)
}
.WHOLEPETSMARKETTXCORPUSCHRISTI {
  background-color:rgba(244, 51, 255, 0.2)
}
.WHOLEPETSMARKETTXBASTROP {
  background-color:rgba(226, 56, 236, 0.2)
}
.WHOLEPETSMARKETTXDRIPPINGSPRINGS {
  background-color:rgba(192, 49, 199, 0.2)
}
.WHOLEPETSMARKETTXGEORGETOWN {
  background-color:rgba(176, 72, 181, 0.2)
}
.WHOLEPETSMARKETTXLAGOVISTA {
  background-color:rgba(212, 98, 255, 0.2)
}
.WOOFSDOGBAKERYHARBOR {
  background-color:rgba(196, 90, 236, 0.2)
}
.TractorSupplyCompany {
  background-color:rgba(167, 74, 199, 0.2)
}
.THEFEEDBARN {
  background-color:rgba(106, 40, 126, 0.2)
}
.KCATERERSNY {
  background-color:rgba(142, 53, 239, 0.2)
}
.PETSETCOFVERMONT {
  background-color:rgba(137, 59, 255, 0.2)
}
.TargetCorp {
  background-color:rgba(127, 56, 236, 0.2)
}
.AmazonPerformanceChargebacks {
  background-color:rgba(108, 45, 199, 0.2)
}
.Lowes {
  background-color:rgba(70, 27, 126, 0.2)
}
.GreaterGood {
  background-color:rgba(87, 27, 126, 0.2)
}
.ANDERSONVALLEYFARMSUPPLY {
  background-color:rgba(125, 27, 126, 0.2)
}
.Petsmart {
  background-color:rgba(132, 45, 206, 0.2)
}
.CHERRYVALLEYFEEDSUPPLIES {
  background-color:rgba(139, 49, 199, 0.2)
}
.DAVESODASPETCITYLUDLOW {
  background-color:rgba(162, 59, 236, 0.2)
}
.DoggyLootLLC {
  background-color:rgba(176, 65, 255, 0.2)
}
.DONATIONS {
  background-color:rgba(126, 88, 126, 0.2)
}
.ETHICALPAWS {
  background-color:rgba(209, 101, 135, 0.2)
}
.Etsy {
  background-color:rgba(247, 120, 161, 0.2)
}
.HUGGYBEARSPETMARKET {
  background-color:rgba(229, 110, 148, 0.2)
}
.KATIESPETDEPOTLOSANGLESCA {
  background-color:rgba(194, 90, 124, 0.2)
}
.MRMOCHASPETSUPPLY {
  background-color:rgba(126, 53, 77, 0.2)
}
.NATURESEMPORIUMTheNaturalDog {
  background-color:rgba(185, 59, 143, 0.2)
}
.NATURESPETMARKETFIVEOAKS {
  background-color:rgba(249, 183, 255, 0.2)
}
.OUTFRONTFARMPETSUPPLY {
  background-color:rgba(230, 169, 236, 0.2)
}
.PAMPEREDPAWSPETSALON {
  background-color:rgba(195, 142, 199, 0.2)
}
.PATTISPETDEPOTHOUSTONTX {
  background-color:rgba(210, 185, 211, 0.2)
}
.PETAPOLUZAPETSUPPLY {
  background-color:rgba(198, 174, 199, 0.2)
}
.NCMPETSLLCDBAPETSCHOICE {
  background-color:rgba(235, 221, 226, 0.2)
}
.PETSNATURALLY {
  background-color:rgba(200, 187, 190, 0.2)
}
.PLOHCorporate {
  background-color:rgba(233, 207, 236, 0.2)
}
.PREMIERPETSUPPLYBH {
  background-color:rgba(252, 223, 255, 0.2)
}
.PSP {
  background-color:rgba(227, 228, 250, 0.2)
}
.PSP {
  background-color:rgba(253, 238, 244, 0.2)
}
.PSP {
  background-color:rgba(198, 222, 255, 0.2)
}
.SMITHLANDSUPPLY {
  background-color:rgba(173, 223, 255, 0.2)
}
.SPRINGCREEKANIMALHOSPITAL {
  background-color:rgba(189, 237, 255, 0.2)
}
.THEHEALTHYPETFREELAND {
  background-color:rgba(224, 255, 255, 0.2)
}
.THEPETWORKS {
  background-color:rgba(194, 223, 255, 0.2)
}
.THEWHOLEPETARSPRINGDALE {
  background-color:rgba(180, 207, 236, 0.2)
}
.WESTPLAINSVETSUPPLY {
  background-color:rgba(183, 206, 236, 0.2)
}
.ZULILYBULK {
  background-color:rgba(82, 243, 255, 0.2)
}
.SweetDeals {
  background-color:rgba(0, 255, 255, 0.2)
}
.SweetDeals {
  background-color:rgba(87, 254, 255, 0.2)
}
.UKUSCAdoggie {
  background-color:rgba(80, 235, 236, 0.2)
}
.LIGONIERPAWSONMAIN {
  background-color:rgba(78, 226, 236, 0.2)
}
.PREMIERPETSUPPLYNOVI {
  background-color:rgba(72, 204, 205, 0.2)
}
.PREMIERPETSUPPLYNORTHVILLE {
  background-color:rgba(67, 198, 219, 0.2)
}
.ANIMALKRACKERS {
  background-color:rgba(154, 254, 255, 0.2)
}
.CHOICEDOGPRODUCTS {
  background-color:rgba(142, 235, 236, 0.2)
}
.ALLABOUTDOGSCATS {
  background-color:rgba(120, 199, 199, 0.2)
}
.AdvancedFoamRecycling {
  background-color:rgba(70, 199, 199, 0.2)
}
.ALWAYSESSENTIALDOGS {
  background-color:rgba(67, 191, 199, 0.2)
}
.WALMARTSTORESINC {
  background-color:rgba(119, 191, 199, 0.2)
}
.LONGARMTECHNOLOGIESLLC {
  background-color:rgba(146, 199, 199, 0.2)
}
.SUNDANCEKENNELSINC {
  background-color:rgba(175, 220, 236, 0.2)
}
.RICHMONDFEEDSERVICEINC {
  background-color:rgba(59, 156, 156, 0.2)
}
.DAYPETSUPPLY {
  background-color:rgba(48, 125, 126, 0.2)
}
.AcademySportsOutdoors {
  background-color:rgba(62, 169, 159, 0.2)
}
.WAGSWALKS {
  background-color:rgba(130, 202, 250, 0.2)
}
.RELIANTATLANTICGROUP {
  background-color:rgba(160, 207, 236, 0.2)
}
.THEFEEDBAGPETSUPPLYCOMPANY {
  background-color:rgba(135, 175, 199, 0.2)
}
.SandyPawsRescueInc {
  background-color:rgba(130, 202, 255, 0.2)
}
.PetFoodWarehouse {
  background-color:rgba(121, 186, 236, 0.2)
}
.MidwestVeterinaryPartnersdbaSandRoad {
  background-color:rgba(86, 109, 126, 0.2)
}
.HometownAnimalSupplyLLC {
  background-color:rgba(102, 152, 255, 0.2)
}
.SmartSourceLLC {
  background-color:rgba(115, 106, 255, 0.2)
}
.AmazonSellerCentralBulk {
  background-color:rgba(207, 236, 236, 0.2)
}
.WagPride {
  background-color:rgba(175, 199, 199, 0.2)
}
.HappyTailsTravelInc {
  background-color:rgba(113, 125, 125, 0.2)
}
.TouchofModern {
  background-color:rgba(149, 185, 199, 0.2)
}
.KrogerdbaVitacostcom {
  background-color:rgba(109, 105, 104, 0.2)
}
.PeapodADUSACommercialHoldingsInc {
  background-color:rgba(110, 106, 107, 0.2)
}
.FaireWholesaleInc {
  background-color:rgba(114, 110, 109, 0.2)
}
.CoastalConservationAssociationInc {
  background-color:rgba(116, 113, 112, 0.2)
}
.BestFriendsPetHotel {
  background-color:rgba(115, 111, 110, 0.2)
}
.BarkgataPetHotelSpa {
  background-color:rgba(97, 109, 126, 0.2)
}
.PaperRecyclingServices {
  background-color:rgba(101, 115, 131, 0.2)
}
.TecaTuAPawsworthyPetEmporium {
  background-color:rgba(100, 109, 126, 0.2)
}
.HOWLISTICLLC {
  background-color:rgba(109, 123, 141, 0.2)
}
.TexasverseLLC {
  background-color:rgba(76, 120, 126, 0.2)
}
.BargainHunt {
  background-color:rgba(76, 125, 126, 0.2)
}
.BeverlysPetCenter {
  background-color:rgba(128, 109, 126, 0.2)
}
.BigLots {
  background-color:rgba(94, 90, 128, 0.2)
}
.LincolnRecycling {
  background-color:rgba(78, 56, 126, 0.2)
}
.EssexTechnologyGroupLLCDBABargainHunt {
  background-color:rgba(21, 27, 84, 0.2)
}
.PetsEtcofVermont {
  background-color:rgba(43, 56, 86, 0.2)
}
.PetParentsStore {
  background-color:rgba(37, 56, 60, 0.2)
}
.PICAPETIncdbaPetsNStuff {
  background-color:rgba(70, 62, 65, 0.2)
}
.MartillaLLC {
  background-color:rgba(113, 125, 125, 0.2)
}
.PetSupermarketInc {
  background-color:rgba(149, 185, 199, 0.2)
}
.WesternPetSupply {
  background-color:rgba(94, 118, 126, 0.2)
}
.AmazonDirectImport {
  background-color:rgba(94, 125, 126, 0.2)
}
.ParkerPetDBAVeterinaryMedicalCnt {
  background-color:rgba(97, 124, 88, 0.2)
}
.WWBazaarVoiceSamples {
  background-color:rgba(52, 135, 129, 0.2)
}
.CentralGardenandPetdbaCentralPetDistribution {
  background-color:rgba(48, 103, 84, 0.2)
}
.CUDDLYBULK {
  background-color:rgba(78, 137, 117, 0.2)
}
.PetsensebyTractorSupply {
  background-color:rgba(37, 65, 23, 0.2)
}
.VRCompaniesLLCDBAYourPetsNaturallyCascade {
  background-color:rgba(56, 124, 68, 0.2)
}
.PatsPetsofMishawaka {
  background-color:rgba(181, 234, 170, 0.2)
}
.PREMIERPETSUPPLYDETROIT {
  background-color:rgba(195, 253, 184, 0.2)
}
.MIDPRODUCTIONLLC {
  background-color:rgba(0, 255, 0, 0.2)
}
.KKVetSupply {
  background-color:rgba(135, 247, 23, 0.2)
}
.MPJNSHELBYINCdbaPremierPetSupply {
  background-color:rgba(95, 251, 23, 0.2)
}
.SALESSAMPLES {
  background-color:rgba(89, 232, 23, 0.2)
}
.SavvyPetParadise {
  background-color:rgba(127, 232, 23, 0.2)
}
.WorldWisePetco {
  background-color:rgba(138, 251, 23, 0.2)
}
.RazorThinkForecast {
  background-color:rgba(177, 251, 23, 0.2)
}
.AMAZONVendorFlex {
  background-color:rgba(204, 251, 93, 0.2)
}
.CentralGardenandPetDBAGeneralPetSupply {
  background-color:rgba(188, 233, 84, 0.2)
}
.PeyIncDBAPetExpoDistibuters {
  background-color:rgba(160, 197, 68, 0.2)
}
.UPCOPetSupplies {
  background-color:rgba(255, 255, 0, 0.2)
}
.WorldWiseLowes {
  background-color:rgba(255, 252, 23, 0.2)
}
.PamperedPupLLC {
  background-color:rgba(255, 243, 128, 0.2)
}
.HomeGoodsInc {
  background-color:rgba(237, 226, 117, 0.2)
}
.SierraTradingPostInc {
  background-color:rgba(237, 218, 116, 0.2)
}
.TJXCanadaDBAHomesense {
  background-color:rgba(234, 193, 23, 0.2)
}
.AmazonCanadaDirectImport {
  background-color:rgba(253, 208, 23, 0.2)
}
.AmazonMexicoDirectImport {
  background-color:rgba(251, 185, 23, 0.2)
}
.OceanStateJobbers {
  background-color:rgba(233, 171, 23, 0.2)
}
.PetFactoryHyVee {
  background-color:rgba(212, 160, 23, 0.2)
}
.TJXCompaniesIncdbaTJMaxx {
  background-color:rgba(199, 163, 23, 0.2)
}
.IMPORTADORAMEXICANADEMASCOTASdbaPETCOMEXICO {
  background-color:rgba(198, 142, 23, 0.2)
}
.MidStatesDistributingLLC {
  background-color:rgba(175, 120, 23, 0.2)
}
.ChewyPrivateLabel {
  background-color:rgba(173, 169, 110, 0.2)
}
.MarshallsofMAInc {
  background-color:rgba(201, 190, 98, 0.2)
}
.CalmComfyKY {
  background-color:rgba(130, 120, 57, 0.2)
}
.GordmansStoresBadDebtRecovery {
  background-color:rgba(251, 177, 23, 0.2)
}
.PREBUILDNOSALE {
  background-color:rgba(232, 163, 23, 0.2)
}
.ZhejiangKaifengImportExportCoLTD {
  background-color:rgba(197, 137, 23, 0.2)
}
.WorldWise {
  background-color:rgba(248, 116, 49, 0.2)
}
.PetFactory {
  background-color:rgba(255, 252, 23, 0.2)
}
.RKIAppsIncDBASocialLadder {
  background-color:rgba(255, 243, 128, 0.2)
}
